import React from 'react';

// reactstrap components

import bg from 'assets/trimogvinn/iphone12collection.png';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';
// core components

import AppStore from 'assets/app-store-badge.png';
import GooglePlay from 'assets/google-play-badge.png';

export const AvailableAppStoreGooglePlay = () => {
  return (
    <>
      <div
        style={{
          padding: 10,
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignSelf: 'center',
          textAlign: 'center',
          flex: 1,
        }}
      >
        <Row className="align-items-center">
          <Col xs={{ size: 4, offset: 2 }}>
          <a href="https://apps.apple.com/no/app/trim-vinn/id1556099051?l=nb"> 
          <img src={AppStore} style={{ maxHeight: 100, minWidth: 70 }} />
          </a>
          </Col>
          <Col xs="4">
            <a href="https://play.google.com/store/apps/details?id=com.trimogvinn_app">
            <img src={GooglePlay} style={{ maxHeight: 100, minWidth: 70 }} />
            </a>
          </Col>
        </Row>
      </div>
    </>
  );
};
