import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from 'react-router-dom';

// screens
import { MainView } from 'Views/MainView/MainView';
import { DataDeletionView } from 'Views/DataDeletion/DataDeletion';
import { TermsView } from 'Views/Terms/TermsView';
import { PrivacyView } from 'Views/Privacy/PrivacyView';

import AuthContext from 'Context/AuthContext';

export const App = () => {
  return (
    <div className='App' id='home'>
      <Router basename='/'>
        <Switch>
          <Route exact path='/'>
            <MainView />
          </Route>
          <Route path='/datadelete'>
            <DataDeletionView />
          </Route>
          <Route path='/terms'>
            <TermsView />
          </Route>
          <Route path='/privacy'>
            <PrivacyView />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};
