import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import bg from 'assets/header_pro_pano.jpg';

// core components

export const HowItWorksSection = () => {
  return (
    <>
      <Container>
        <Row>
          <Col className="mr-auto ml-auto text-center m-5" md="10">
            <h2 className="title">Slik fungerer det</h2>
            <p>
              Trim&Vinn gjør det enkelt å gjennomføre trimkonkurranser og idrettsarrangementer med
              digital påmelding og tidtaking!
              </p>
            <h5>I tråd med smittevernregler:</h5>
            <p >
              Ved hjelp av QR-koder og selvstending tidtaking kan du delta på planlagte arrangementer, i
              henhold til gjeldende Covid-19-tiltak og med minimal sosial kontakt.
            </p>
            <h5>QR-kode og tidtaking:</h5>
            <p >
              Appen inneholder integrert QR-skanner for registrering av start og målgang. Vanlig mobilkamera støttes også.
              Etter registrering kan du starte tidtakingen når du er klar.
              </p>
            <h5>Se fullstendinge resultatlister:</h5>
            <p >
              Resultatene er enkelt tilgjengelig i appen, og vil oppdateres fortløpende underveis i konkurransen.
            </p>
            <h5>Del med venner:</h5>
            <p >
              Resultater og konkurranser kan enkelt deles via Facebook.
</p>


            {/* Registrer din digitale trim og vinn kasse hos trimogvinn.no.
              <br />
              Heng ut registrerings plakat på dine trim steder.
              <br />
              Ha oversikt, kjør trekning og send ut premier til deltakere fra
              Minside. */}

          </Col>
          <Col className="mr-auto ml-auto" md="12">
            <img src={bg} style={{ borderRadius: 30 }} />
          </Col>
        </Row>
      </Container>
    </>
  );
};
