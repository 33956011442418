import React from 'react';
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';

import logo from 'assets/img/tov_logo-standard.png'

export const FixedNavbar = () => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [logoOnScroll, setlogoOnScroll] = React.useState(false)

  const [navbarColor, setNavbarColor] = React.useState(
    (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
      ? ''
      : ' navbar-transparent'
  );
  const [buyButtonColor, setBuyButtonColor] = React.useState(
    (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
      ? 'info'
      : 'neutral'
  );
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor('');
        setBuyButtonColor('info');
        setlogoOnScroll(true)
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor(' navbar-transparent');
        setBuyButtonColor('neutral');
        setlogoOnScroll(false)
      }
    };
    window.addEventListener('scroll', updateNavbarColor);
    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });

  const ScrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={'fixed-top' + navbarColor} color="white" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <a
                onClick={() => {
                  ScrollToTop();
                }}
              >
                {logoOnScroll ? (
                  <img className="logo-scroll" src={logo} />)
                  : (null
                  )}
              </a>
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <NavItem
                style={{ marginLeft: 10, marginRight: 10 }}
                active={window.location.pathname === '/registrer'}
              >
                <Link
                  activeClass="active"
                  to="1"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Om
                </Link>
              </NavItem>
              <NavItem
                style={{ marginLeft: 10, marginRight: 10 }}
                active={window.location.pathname === '/minside'}
              >
                <Link
                  activeClass="active"
                  to="2"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  App
                </Link>
              </NavItem>

              {/* <NavItem
                style={{ marginLeft: 10, marginRight: 10 }}
                active={window.location.pathname === '/login'}
              >
                <Link
                  activeClass="active"
                  to="3"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Rettigheter
                </Link>
              </NavItem> */}

              <NavItem
                style={{ marginLeft: 10, marginRight: 10 }}
                active={window.location.pathname === '/login'}
              >
                <Link
                  activeClass="active"
                  to="4"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Kontakt
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};
