import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

// styles
import '../src/assets/css/bootstrap.min.css';
import '../src/assets/scss/now-ui-kit.scss?v=1.4.0';
import '../src/assets/demo/demo.css?v=1.4.0';
import '../src/assets/demo/react-demo.css?v=1.4.0';
import '../src/assets/demo/nucleo-icons-page-styles.css?v=1.4.0';

// Custom styles

import 'Views/MainView/MainViewStyles.css';
import 'App.css';

// Router
import { BrowserRouter } from 'react-router-dom';
// import GlobalState from 'Context/GlobalState';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
