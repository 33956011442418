import React from 'react';

// reactstrap components
import { Button, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import bg from 'assets/trimogvinn/iphone12collection.png';
// core components

export const Section3 = () => {
  return (
    <>
      <div className="Iphone-Section"></div>
    </>
  );
};
