import React from 'react';

// reactstrap components
import { Button, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import bg from 'assets/smallBg.png';
import bg2 from 'assets/trimogvinn/iphone12.png';
import logo from 'assets/img/TOV_logo-HVIT.png';
// core components

export const Header = () => {
  const [height, setHeight] = React.useState();
  const [width, setWidth] = React.useState();

  const handleResize = (e) => {
    setWidth(e.target.innerWidth);
    setHeight(e.target.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  return (
    <>
      <div className="headerContainer">
        <div className="headerContainer-image">
          <div
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            <img src={bg} style={{ maxHeight: '100vh' }} />
            {width > 800 && <img src={bg2} style={{ maxHeight: '80vh' }} />}
          </div>
        </div>
        {/* <div className="FrontPageLogo"></div> */}
        <div className="content-center">
          <Container>
            <img className="logo-img" src={logo} />
            <h3 className="title">
              Digital løsning for arrangementer og konkurranser.
            </h3>
            <div className="text-center"></div>
          </Container>
        </div>
      </div>
    </>
  );
};
