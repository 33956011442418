import React from 'react';

// reactstrap components
import { Button, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import bg from 'assets/trimogvinn/iPhone12Mockup.png';
// core components

export const IphoneSection = () => {
  return (
    <>
      <div className="Iphone-Section">
        <img src={bg} />
      </div>
    </>
  );
};
