import React from 'react';
// react plugin used to create google maps
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import axios from 'axios';
import Loader from 'react-loader-spinner';

import bg from 'assets/img/bg7.jpg';

// core components
export const Contact = () => {
  var testing = 0;

  var UPLOAD_URL = testing ? 'http://localhost:5000/mail' : '/mail';

  const [first1Focus, setFirst1Focus] = React.useState(false);
  const [last1Focus, setLast1Focus] = React.useState(false);
  const [email1Focus, setEmail1Focus] = React.useState(false);

  const [loading, setloading] = React.useState(false);
  const [enableButton, setenableButton] = React.useState(true);
  const [info, setInfo] = React.useState({
    fornavn: '',
    etternavn: '',
    email: '',
    message: '',
  });

  React.useEffect(() => {
    VerifyButton();
    return () => {
      setenableButton(true);
    };
  }, [info]);

  const sendMessage = async () => {
    setloading(true);
    console.log(info);
    try {
      const res = await axios.post(UPLOAD_URL, info).then((res) => {
        console.log(res.data);
        setInfo({ fornavn: '', etternavn: '', email: '', message: '' });
        setloading(false);
      });
    } catch (err) {
      console.log(err);
      window.alert(err);
      setInfo({ fornavn: '', etternavn: '', email: '', message: '' });
      setloading(false);
    }
  };

  const VerifyButton = () => {
    if (!info.message == '' && !info.name == '' && !info.email == '') {
      setenableButton(false);
    } else {
      setenableButton(true);
    }
  };

  const LoadingSpinner = () => {
    return (
      <div className="overlay">
        <Loader
          className="loader"
          type="ThreeDots"
          color="rgba(0,0,240,.6)"
          height="100"
          width="100"
        />
      </div>
    );
  };
  return (
    <>
      <div className="cd-section" id="contact-us">
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage: `url(${bg})`,
          }}
        >
          <Container>
            <Row>
              <Col md="5">
                <h2 className="title">Ta Kontakt</h2>
                <h4 className="description">
                  Har du spørsmål om Trim&Vinn eller om oss? Ta kontakt for en
                  hyggelig prat.
                </h4>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons location_pin"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Du finner oss på kontoret</h4>
                    <p className="description">
                      Petterdass gt 3,<br></br>
                      8656 Mosjøen, <br></br>
                      Norge
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons tech_mobile"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Eller på telefon</h4>
                    <p className="description">
                      Fredrik Nicolaysen <br></br>
                      +47 99357299 <br></br>
                      Man - Fre, 8:00-22:00
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="ml-auto mr-auto" md="5">
                <Card className="card-contact card-raised">
                  <Form id="contact-form1" method="post" role="form">
                    <CardHeader className="text-center">
                      <CardTitle tag="h4">Kontakt oss</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="pr-2" md="6">
                          <label>Fornavn</label>
                          <InputGroup
                            className={first1Focus ? 'input-group-focus' : ''}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons users_circle-08"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              aria-label="Fornavn..."
                              placeholder="Fornavn..."
                              type="text"
                              onFocus={() => setFirst1Focus(true)}
                              onBlur={() => setFirst1Focus(false)}
                              value={info.fornavn}
                              onChange={(e) =>
                                setInfo({ ...info, fornavn: e.target.value })
                              }
                            ></Input>
                          </InputGroup>
                        </Col>
                        <Col className="pl-2" md="6">
                          <FormGroup>
                            <label>Etternavn</label>
                            <InputGroup
                              className={last1Focus ? 'input-group-focus' : ''}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons text_caps-small"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                aria-label="Etternavn..."
                                placeholder="Etternavn..."
                                type="text"
                                onFocus={() => setLast1Focus(true)}
                                onBlur={() => setLast1Focus(false)}
                                value={info.etternavn}
                                onChange={(e) =>
                                  setInfo({
                                    ...info,
                                    etternavn: e.target.value,
                                  })
                                }
                              ></Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <label>Email addresse</label>
                        <InputGroup
                          className={email1Focus ? 'input-group-focus' : ''}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_email-85"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            autoComplete="email"
                            placeholder="Email addresse..."
                            type="email"
                            value={info.email}
                            onFocus={() => setEmail1Focus(true)}
                            onBlur={() => setEmail1Focus(false)}
                            onChange={(e) =>
                              setInfo({ ...info, email: e.target.value })
                            }
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <label>Melding</label>
                        <Input
                          id="message"
                          name="message"
                          rows="6"
                          type="textarea"
                          value={info.message}
                          onChange={(e) =>
                            setInfo({ ...info, message: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                      <Row>
                        <Col md="6">
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox"></Input>
                              <span className="form-check-sign"></span>
                              I'm not a robot
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <Button
                            className="btn-round pull-right"
                            color="info"
                            enableButton={enableButton}
                            onClick={() => sendMessage()}
                          >
                            Send Melding
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {loading ? <LoadingSpinner /> : null}
      </div>
    </>
  );
};
