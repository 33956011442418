import React from 'react';

// reactstrap components

import bg from 'assets/trimogvinn/iphone12collection.png';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';
// core components

import masterCard from 'assets/mastercard.png';
import vipps from 'assets/vipps.png';
import visa from 'assets/visa.png';

export const VerifyedBy = () => {
  return (
    <>
      <div
        style={{
          padding: 10,
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignSelf: 'center',
          textAlign: 'center',
          flex: 1,
        }}
      >
        <Row className="align-items-center">
          <Col xs="4">
            <img src={masterCard} />
          </Col>
          <Col xs="4">
            <img src={vipps} />
          </Col>
          <Col xs="4">
            <img src={visa} />
          </Col>
        </Row>
      </div>
    </>
  );
};
