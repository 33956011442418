import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';

import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';

// core components
import { Header } from 'Views/MainView/Components/Header.js';
import { FixedNavbar } from 'Views/MainView/Components/Navbar';

// Sections
import { IphoneSection } from 'Views/MainView/Sections/IphoneSection';
import { HowItWorksSection } from 'Views/MainView/Sections/HowItWorksSection';
import { Section3 } from 'Views/MainView/Sections/Section3';
import { Contact } from 'Views/MainView/Sections/Contact';
import { VerifyedBy } from 'Views/MainView/Sections/VerifyedBy';
import { AvailableAppStoreGooglePlay } from 'Views/MainView/Sections/AppStoreGooglePlay';

import FooterBlackSocial from 'Views/MainView/Components/FooterBlackSocial';

export const MainView = (props) => {
  return (
    <>
      <FixedNavbar />
      <Header />

      <div className="Main">
        <div className="BackGroundBox">
          <Element name="1">
            <HowItWorksSection />
          </Element>
        </div>
        <div className="BackGroundBox">
          <Element name="2">
            <IphoneSection />
          </Element>
        </div>
        <div className="BackGroundBox">
          <VerifyedBy />
        </div>
        <div className="BackGroundBox">
          <AvailableAppStoreGooglePlay />
        </div>
        <Element name="3">
          <Section3 />
        </Element>
        <Element name="4">
          <Contact />
        </Element>
      </div>
      <FooterBlackSocial />
    </>
  );
};
