import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';

import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';

// core components
import { Header } from 'Views/MainView/Components/Header.js';
import { FixedNavbar } from 'Views/MainView/Components/Navbar';
import FooterBlackSocial from 'Views/MainView/Components/FooterBlackSocial';
import axios from 'axios';
import Loader from 'react-loader-spinner';

export const DataDeletionView = (props) => {
  const [email, setEmail] = React.useState('');
  const [loading, setloading] = React.useState(false);
  const [enableButton, setenableButton] = React.useState(true);

  var testing = false;

  var UPLOAD_URL = testing
    ? 'http://localhost:5000/api/email/datadelete'
    : '/api/email/datadelete';

  const AUTH_TOKEN = '968da4e7-a346-41d2-811d-79a0fc986fff';
  axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

  const sendMessage = async () => {
    setloading(true);

    try {
      const res = await axios.post(UPLOAD_URL, { email: email }).then((res) => {
        setloading(false);
        setEmail('');
        window.alert(
          'Request for delete is sent! You wil get a email when it is done!'
        );
      });
    } catch (err) {
      console.log(err);
      window.alert(err);
      setloading(false);
    }
  };

  const LoadingSpinner = () => {
    return (
      <div className="overlay">
        <Loader
          className="loader"
          type="ThreeDots"
          color="rgba(0,0,240,.6)"
          height="100"
          width="100"
        />
      </div>
    );
  };
  return (
    <>
      <div className="Main">
        <div className="BackGroundBox">
          <h4>Request Data deletion</h4>
          <p>
            Request Trim og Vinn to have all of your userdata, and race data
            deleted.
          </p>
          <p>
            A confirmation mail will be sent to your email, when your data is
            deleted.
          </p>
          <h5>Please enter your email account</h5>
          <Input
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <Button
            disabled={email === '' ? true : false}
            onClick={() => sendMessage()}
            color="primary"
          >
            Delete!
          </Button>
        </div>
      </div>
      <FooterBlackSocial />
      {loading ? <LoadingSpinner /> : null}
    </>
  );
};
